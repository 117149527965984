import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { slugify } from "../lib/helpers";

const GetEmailCrashCourse = props => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleShow = () => setModalIsOpen(true);
  const handleClose = () => setModalIsOpen(false);

  return (
    <>
      <Button onClick={handleShow} variant="outline-primary">
        Get email crash course
      </Button>
      <Modal show={modalIsOpen} onHide={handleClose} centered size="md">
        <Modal.Body className="p-3">
          <div className="overline mb-1">
            {props.network.title} · {props.title}
          </div>
          <h3 className="mb-1" style={{ lineHeight: 1.2 }}>
            Learn the ropes
            <br />
            one email at a time
          </h3>
          <p>Coming soon – sign up now and we'll enroll you when we launch.</p>
          <Form
            name="email-course-signup"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="email-course-signup" />
            <input hidden type="hidden" name="network" value={props.network.slug.current} />
            <input hidden type="hidden" name="role" value={slugify(props.title)} />
            <Form.Control type="string" placeholder="Name" name="name" className="mb-2" />
            <Form.Control type="email" placeholder="Email" name="email" className="mb-2" required />
            <div className="d-flex">
              <Button type="submit" variant="success" className="mr-2">
                Sign up
              </Button>
              <Button variant="light" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GetEmailCrashCourse;
