import { graphql } from "gatsby";
import React from "react";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Role from "../components/Role";
import SEO from "../components/seo";
import SpotlightLayout2 from "../components/SpotlightLayout2";
import Layout from "../containers/layout";

export const query = graphql`
  query RoleTemplateQuery($id: String!) {
    role: sanityRole(id: { eq: $id }) {
      id
      title
      _rawSummary(resolveReferences: { maxDepth: 10 })
      _rawResponsibilities(resolveReferences: { maxDepth: 10 })
      _rawCost2(resolveReferences: { maxDepth: 10 })
      _rawBenefit2(resolveReferences: { maxDepth: 10 })
      _rawRewards(resolveReferences: { maxDepth: 10 })
      _rawPenalties(resolveReferences: { maxDepth: 10 })
      _rawGetStarted(resolveReferences: { maxDepth: 10 })
      _rawRequirements(resolveReferences: { maxDepth: 10 })
      systemComponents {
        systemComponent {
          id
          title
          image {
            asset {
              fluid(maxWidth: 400) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      avatar {
        asset {
          fixed(width: 250) {
            ...GatsbySanityImageFixed
          }
        }
      }
      network {
        title
        slug {
          current
        }
      }
    }
  }
`;

const RoleTemplate = props => {
  const { data, errors, pageContext } = props;
  const { spotlightLayoutResource } = pageContext;
  const role = data && data.role;

  return (
    <Layout>
      {errors && <SEO title="GraphQL  Error" />}
      {role && (
        <SEO
          title={`${role.title} | ${role.network.title}`}
          // description={toPlainText(role._rawExcerpt)}
          image={role._rawSocialImage ? role._rawSocialImage : role._rawMainImage || null}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {role && (
        <>
          <SpotlightLayout2 resource={spotlightLayoutResource}>
            <Role {...role} {...props} />
          </SpotlightLayout2>
        </>
      )}
    </Layout>
  );
};

export default RoleTemplate;
