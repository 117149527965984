import { Link } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { getLink } from "../routes";
import GetEmailCrashCourse from "./GetEmailCrashCourse";
import PortableText from "./portableText";

const Role = props => {
  const {
    title,
    avatar,
    _rawResponsibilities,
    _rawRequirements,
    _rawBenefit2,
    _rawCost2,
    _rawRewards,
    _rawPenalties,
    _rawSummary,
    _rawGetStarted,
    systemComponents,
    network
  } = props;

  return (
    <Container>
      <Row className="d-flex align-items-center mb-3">
        <Col xl={8}>
          <h1 className="display-4">{title}</h1>
          <GetEmailCrashCourse {...props} />
        </Col>
        <Col xl={4}>
          <Img fixed={avatar && avatar.asset.fixed} className="d-inline-block" />
        </Col>
      </Row>
      <div className="lead w-text mb-3">
        <PortableText blocks={_rawSummary} />
      </div>
      <Row className="mb-3">
        <Col xs={12} xl={8}>
          <div className="mb-3 d-xl-none">
            <div className="overline mb-1">Essentials</div>
            {systemComponentCards(systemComponents, network, "d-flex flex-wrap", "pr-2 w-50 mb-2")}
          </div>
          <div className="mb-3">
            <div className="overline mb-1">Get Started</div>
            {_rawGetStarted ? (
              <>
                <PortableText blocks={_rawGetStarted} />
              </>
            ) : (
              <>Nothing to show here yet</>
            )}
          </div>
          <div className="mb-3">
            <div className="overline mb-1">Benefits</div>
            {_rawBenefit2 ? (
              <>
                <PortableText blocks={_rawBenefit2} />
              </>
            ) : (
              <>No benefits to show yet</>
            )}
          </div>
          <div className="mb-3">
            <div className="overline mb-1">Rewards</div>
            {_rawRewards ? (
              <>
                <PortableText blocks={_rawRewards} />
              </>
            ) : (
              <>No rewards to show yet</>
            )}
          </div>
          <div className="mb-3">
            <div className="overline mb-1">Responsibilities</div>
            {_rawResponsibilities ? (
              <>
                <PortableText blocks={_rawResponsibilities} />
              </>
            ) : (
              <>No responsibilities to show yet</>
            )}
          </div>
          <div className="mb-3">
            <div className="overline mb-1">Requirements</div>
            {_rawRequirements ? (
              <>
                <PortableText blocks={_rawRequirements} />
              </>
            ) : (
              <>No requirements to show yet</>
            )}
          </div>
          <div className="mb-3">
            <div className="overline mb-1">Costs</div>
            {_rawCost2 ? (
              <>
                <PortableText blocks={_rawCost2} />
              </>
            ) : (
              <>No costs to show yet</>
            )}
          </div>
          <div className="mb-3">
            <div className="overline mb-1">Penalties</div>
            {_rawPenalties ? (
              <>
                <PortableText blocks={_rawPenalties} />
              </>
            ) : (
              <>No penalties to show yet</>
            )}
          </div>
        </Col>
        <Col xl={4} className="d-none d-xl-block">
          <div className="mb-1 overline">Essentials</div>
          {systemComponentCards(systemComponents, network)}
        </Col>
      </Row>
    </Container>
  );
};

export default Role;

function systemComponentCards(
  systemComponents,
  network,
  containerClassName = "d-flex flex-column",
  itemClassName = "mb-2"
) {
  return systemComponents.length > 0 ? (
    <div className={containerClassName}>
      {systemComponents.map(node => {
        const { id, image, title } = node.systemComponent;
        return (
          <div className={itemClassName} key={id}>
            <Link
              to={getLink(network.slug.current, "systemComponent", title)}
              style={{ textDecoration: "none" }}
            >
              <Card>
                {console.dir(title, image)}
                {image && image.asset && (
                  <Img
                    fluid={image.asset.fluid}
                    className="mb-0 img-fluid rounded-top"
                    style={{ maxHeight: 150 }}
                  />
                )}
                <Card.Body>
                  <div>{title}</div>
                </Card.Body>
              </Card>
            </Link>
          </div>
        );
      })}
    </div>
  ) : (
    <>Nothing to show here yet</>
  );
}
